<template>
    <div class="day" v-bind:class="{'weekend': date && (date.isoWeekday() === 6 || date.isoWeekday() === 7), shift: !icon &&  shifts && shifts.length > 0}" @click="EmitClick">
        <div class="background" v-bind:style="{background: background}"></div>
        <i class="fas fa-eye-slash" v-if="shifts.length > 0 && shifts.find(s => !s.published)"></i>
        <div class="top" v-if="shifts && shifts.length > 0">{{top ? top : shiftDisplay.top}}</div>
        <div class="bottom" v-if="shifts && shifts.length > 0">{{bottom ? bottom : shiftDisplay.bottom}}</div>
        <div v-if="(icon && (!shifts || shifts.length === 0)) || dateIconDesired || desiredBadge" class="icon-container">
            <div v-if="icon" v-bind:class="[icon, {white: background}]" v-bind:style="{color: GetContrastingColor(background)}">
                <i class="fas fa-times" v-tooltip="'Nicht verfügbar'"></i>
                <i class="fas fa-slash" v-tooltip="'Kein Vertrag'"></i>
                <i class="fas fa-umbrella-beach" v-tooltip="'Urlaub'"></i>
                <i class="fas fa-briefcase-medical" v-tooltip="'Krankenstand'"></i>
                <i class="fas fa-user-graduate" v-tooltip="'Ausbildung'"></i>
                <i class="fas fa-wallet" v-tooltip="'Lohnfortzahlung'"></i>
                <i class="fas fa-gift" v-tooltip="'Feiertagsausgleich'"></i>
            </div>
            <div v-if="dateIconDesired" v-bind:class="dateIconDesired">
                <i class="fas fa-thumbs-up" v-tooltip="'Gewünscht'"></i>
                <i class="fas fa-thumbs-down" v-tooltip="'Unerwünscht'"></i>
            </div>
            <div class="contains unavailable" v-if="desiredBadge">
                <i class="fas fa-times" v-tooltip="'Nicht verfügbar'"></i>
            </div>
        </div>
        <div class="spot-icon" v-if="spotColor || shiftDisplay.spotColor" v-bind:style="{background: (spotColor ? spotColor : shiftDisplay.spotColor) + ' !important'}"></div>
        <div class="shift-count" v-if="shifts.length > 1">{{shifts.length}}</div>
    </div>
</template>

<script>
    export default {
        name: "rosterday",
        data(){
            return {
                top: null,
                bottom: null,
                icon: null,
                date: null,
                shifts: null,
                background: null,
                dateIconDesired: null,
                desiredBadge: null,
                spotColor: null,
                spotMap: {},
            }
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];
                    if(this.shifts && this.shifts.length === undefined) {
                        let temp = [];
                        temp.push(this.shifts);
                        this.shifts = temp;
                    }
                }
            }
        },
        computed: {
            shiftDisplay(){
                let top = '';
                let bottom = '';
                let shifts = [];
                for(let x in this.shifts){
                    let shift = this.shifts[x];
                    let shiftTemp = {};
                    for(let key in shift) {
                        shiftTemp[key] = shift[key];
                    }
                    if(!shiftTemp.fromDate && shiftTemp.from) shiftTemp.fromDate = this.$helpers.getMomentFromStringTZ(shiftTemp.from);
                    if(!shiftTemp.toDate && shiftTemp.to) shiftTemp.toDate = this.$helpers.getMomentFromStringTZ(shiftTemp.to);
                    shifts.push(shiftTemp);
                }

                if(shifts.length == 1){
                    top = shifts[0].fromDate ? shifts[0].fromDate.format('HH:mm') : '--:--';
                    bottom = shifts[0].toDate ? shifts[0].toDate.format('HH:mm') : '--:--';
                } else if(shifts.length > 1){
                    top = shifts.reduce((lowest, current) => { return lowest.fromDate < current.fromDate ? lowest : current});
                    top = top.fromDate ? top.fromDate.format('HH:mm') : '--:--';
                    bottom = shifts.reduce((highest, current) => { return highest.toDate > current.toDate ? highest : current});
                    bottom = bottom.toDate ? bottom.toDate.format('HH:mm') : '--:--';
                }

                let spotMap = this.spotMap;

                let color = shifts.find(s => spotMap[s.spotId] && spotMap[s.spotId].color);
                if(color) {
                    color = spotMap[color.spotId].color;
                }
                return {
                    top: top,
                    bottom: bottom,
                    spotColor: color,
                };
            },
        },
        props: {
            state: Object,
        },
        methods: {
            EmitClick($event){
                this.$emit('click',$event);
            },
            GetContrastingColor(hex){
                if(!hex) return '#444444';
                let hsl = this.$helpers.hexToHSLObject(hex);
                if(hsl.l < 80) return 'white';
                else return '#444444';
            }
        },
    }
</script>

<style scoped>
    .day{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background: var(--contrast-1);*/
    }
    .day.shift{
        /*background: #e3e8d4;*/
    }

    .day.weekend{
        background: var(--contrast-1);
    }

    .top,.bottom{
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9px;
    }


    .day.weekend i, .day.shift i{
        color: white !important;
    }

    .bottom{
        top: initial;
        bottom: 0;
    }
    .day .icon-container{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .day .icon-container div{
        display: none;
        float: left;
        width: 100%;
        height: 100%;
        left: 0;
        bottom:0;
        right:0;
        text-align:center;
        font-size: 10;
        padding-top:3px important;
    }

    .day .icon-container .contains{
        display: inline-block;
    }

    .day .icon-container .contains i{
        display: none;
        position:relative;
        top:0;
        left:0;
        bottom:0;
        right:0;
        font-size:18px;
        color: inherit;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    .day.weekend .icon-container .contains i{
        /*color:#fff;*/
    }

    .day .icon-container .vacation .fa-umbrella-beach{
        display: flex;
        /*color:#dec92f;*/
    }

    .day .icon-container .contract .fa-slash{
        display: flex;
    }

    .day .icon-container .sickness .fa-briefcase-medical{
        display: flex;
        /*color:#e5154f;*/
    }

    .day .icon-container .schooling .fa-user-graduate{
        display: flex;
        /*color:#348feb;*/
    }

    .day .icon-container .wagecontinuation .fa-wallet{
        display: flex;
        /*color:#eb7d34;*/
    }

    .day .icon-container .holidayadjustment .fa-gift{
        display: flex;
        /*color:#40eded;*/
    }

    .day .icon-container .unavailable .fa-times{
        display: flex;
    }

    .day .white i{
        /*color: white !important;*/
    }
    .background{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: .2;
    }

    .spot-icon{
      position: absolute;
      font-size: 12px;
      left: -2px;
      bottom: -2px;
      z-index: 1;
      height: 8px;
      width: 8px;
      border-radius: 100px;
      border: 1px solid #6d8d22;
  }

    .desired-icon{
       position: absolute;
       font-size: 10px;
       left: -1px;
       bottom: -1px;
       z-index: 1;
       height: 10px;
       width: 10px;
       border-radius: 0px;
       border: none;
       color: #5f5f5f;
   }

    .undesired-icon{
         position: absolute;
         font-size: 10px;
         left: -1px;
         bottom: -1px;
         z-index: 1;
         height: 10px;
         width: 10px;
         border-radius: 0px;
         border: none;
         color: #5f5f5f;
     }

    .drag-container .day, .shift .day{
        height: 45px;
        width: 45px;
        position: relative;
        cursor: pointer;
        transition: .2s;
    }

    .drag-container .day:hover, .shift .day:hover,.drag-container .day.selected, .shift .day.selected{
        transform: scale(1.1);
        box-shadow: 2px 2px 5px -2px black;
    }

    .drag-container .day *, .shift .day *{
        pointer-events: none;
    }

    .drag-container .day .top, .shift .day .top{
        background: #c1e077;
        font-size: 11px;
    }

    .drag-container .day .bottom, .shift .day .bottom{
        background: #879d53;
        font-size: 11px;
    }

    .fa-eye-slash{

        position: absolute;
        font-size: 12px;
        right: -3px;
        top: -3px;
        z-index: 1;
        height: 12px;
        border-radius: 100px;
        background: #c1e077;
    }

    .fa-eye-slash:before{
        color: black !important;
    }
</style>